<template>
  <div class="parlay-premium">
    <template v-if="showDesglose">
      <GMakingMoney
        @show="
          show();
          messageAnalyticsSeeDetails();
        "
      />
    </template>
    <template v-else>
      <div class="parlay-premium__making">
        <MakingMoney
          @show="
            show();
            messageAnalyticsSeeDetails();
          "
        />
      </div>
      <div class="parlay-premium__sub-menu">
        <ParlaySubMenu @select="onParlaySelect" :available-bets="availableParlays" :min-parlay-index="minParlayIndex" />
      </div>
      <template v-if="selectedParlay.bets.length">
        <div class="parlay-premium__parlay">
          <div v-for="(bet, index) of selectedParlay.bets" :key="index">
            <div>
              <ParlayGame :is-mobile="parlayGameMobileFlag" :bet="bet" />
            </div>
          </div>
        </div>
        <div class="parlay-premium__info">
          <ParlaySelectedInfo :parlay-bets="selectedParlay.bets" />
        </div>
      </template>
      <template v-else-if="areParlaysFilled">
        <div class="parlay-premium__no-doubles">
          No existen en Caliente las suficientes apuestas de este torneo. Esperaremos a que Caliente actualice sus
          apuestas para poder proporcionarte la mejor sugerencia posible.
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { fetchParlayPremium } from '@/utils/api/bets';
import { parlayDetails } from '@/utils/analytics';

export default {
  name: 'ParlayPremium',
  components: {
    ParlayGame: () => import('@/components/Sections/GolTipster-v2/General/ParlayGame'),
    ParlaySelectedInfo: () => import('@/components/Sections/GolTipster-v2/Parlay/ParlaySelectedInfo'),
    MakingMoney: () => import('@/components/Sections/GolTipster-v2/General/MakingMoney'),
    GMakingMoney: () => import('@/components/Sections/GolTipster-v2/General/GMakingMoney'),
    ParlaySubMenu: () => import('@/components/Sections/GolTipster-v2/Parlay/ParlaySubMenu'),
  },
  data() {
    return {
      parlayGameMobileFlag: false,
      showDesglose: false,
      availableParlays: [],
      selectedParlayIndex: 0,
      selectedParlay: {
        bets: [],
      },
      minParlayIndex: 0,
      areParlaysFilled: false,
    };
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapState('betsGeneral', ['refreshIntervalCounter']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  watch: {
    displayWidth: {
      immediate: true,
      handler(newValue) {
        this.parlayGameMobileFlag = newValue < 473;
      },
    },
    refreshIntervalCounter() {
      this.fillInitialData();
    },
    availableParlays(newValue) {
      if (newValue && newValue.length) {
        let minValidParleyIndex = -1;
        const nowMillis = Date.now();
        newValue.forEach((parlay, index) => {
          let isParlayInvalid = false;
          for (const bet of parlay.bets) {
            const betDateTimeMillis = new Date(`${bet.date}T${bet.game_hour}`).valueOf();
            if (betDateTimeMillis < nowMillis) {
              // eslint-disable-next-line no-param-reassign
              isParlayInvalid = true;
              break;
            }
          }
          if (isParlayInvalid) {
            parlay.bets = [];
          } else if (minValidParleyIndex === -1) {
            minValidParleyIndex = index;
            this.minParlayIndex = index;
          }
        });
        this.areParlaysFilled = true;
      }
    },
  },
  methods: {
    ...mapActions('betsGeneral', ['getParlayFree']),
    show() {
      this.showDesglose = !this.showDesglose;
    },
    messageAnalyticsSeeDetails() {
      parlayDetails({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
    },
    async fillParlayInfo() {
      try {
        const parlaysByTournament = await fetchParlayPremium();
        parlaysByTournament.tournaments.forEach(tournament => {
          tournament.minParlayIndex = this.getMinParlayIndex(tournament);
          tournament.parlays.forEach(parlay => {
            parlay.bets.forEach(bet => {
              bet.isCup = tournament.tournament_id === 33;
              bet.tournamentName = tournament.tournament_name;
              bet.prob_actual = this.getActualProbability(bet.odd);
            });
          });
        });
        // liga mx
        let parlayTournament = parlaysByTournament.tournaments.find(tournament => tournament.tournament_id === 1);
        if (!parlayTournament || parlayTournament.minParlayIndex === -1) {
          // culaquier otro que no sea mixto
          parlayTournament = parlaysByTournament.tournaments
            .filter(tournament => tournament.tournament_id !== 0 && tournament.tournament_id !== 1)
            .find(tournament => tournament.minParlayIndex !== -1);
        }
        if (!parlayTournament || parlayTournament.minParlayIndex === -1) {
          // mixto
          parlayTournament = parlaysByTournament.tournaments.find(tournament => tournament.tournament_id === 0);
        }
        if (parlayTournament && parlayTournament.minParlayIndex !== -1) {
          this.availableParlays = parlayTournament.parlays;
          this.selectedParlayIndex = parlayTournament.minParlayIndex;
          this.setSelectedParlay();
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    getMinParlayIndex(tournament) {
      let minParlayIndex = -1;
      for (let i = 0; i < tournament.parlays.length; i++) {
        if (tournament.parlays[i].bets.length) {
          return i;
        }
      }
      return minParlayIndex;
    },
    setSelectedParlay() {
      if (this.availableParlays.length) {
        this.selectedParlay = this.availableParlays[this.selectedParlayIndex];
      }
    },
    getActualProbability(odd) {
      return odd > 0 ? (100 / (odd + 100)) * 100 : (Math.abs(odd) / (Math.abs(odd) + 100)) * 100;
    },
    onParlaySelect(parlayIndex) {
      this.selectedParlayIndex = parlayIndex;
      this.setSelectedParlay();
    },
    async fillInitialData() {
      try {
        await this.getParlayFree();
        await this.fillParlayInfo();
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  created() {
    this.fillInitialData();
  },
};
</script>

<style scoped lang="scss">
.parlay-premium {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  padding-bottom: 2em;

  &__making {
    width: 100%;
    margin-top: 34px;
    margin-bottom: 14px;
  }

  &__sub-menu {
    margin-top: 1em;
    width: 100%;
    max-width: 68em;
    padding: 0 0.6em;
  }

  &__parlay {
    border-radius: 1.5em;
    border: solid 1px #132839;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 1em;
    padding: 0.5em 0.375em;

    & > div {
      padding: 0 0.375em;
    }

    @media screen and (max-width: 919px) {
      width: fit-content;
      max-width: 650px;
    }

    @media screen and (max-width: 689px) {
      width: 438px;
    }

    @media screen and (max-width: 473px) {
      width: min-content;
    }
  }

  &__info {
    width: 100%;
    max-width: 60.25em;
    padding: 0 0.6em;
  }

  &__no-doubles {
    width: 100%;
    font-size: 1.25em;
    height: 23.5em;
    padding: 5.5em 4em 2em;
    font-family: Roboto-Regular, sans-serif;

    @media screen and (max-width: 580px) {
      padding: 3.5em 2em;
      height: fit-content;
    }
  }
}
</style>
